import React, { useContext } from "react"
import { useStaticQuery, graphql, navigate } from "gatsby"
import { LanguageContext } from "../../context"
import Icon from "../icon"
import image from "../../assets/images/homepage/la-nostra-comunitat/img-1@3x.png"
import Event from "./events-item"
import moment from "moment"
import Utils from "../../utils"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import NoSearchResults from "../../utils/no-search-results"

const alias ="/events"

 const EventsList = (props) => {

   const { title, month, data, lang, hidemonth } = props
   const { t } = useContext(LanguageContext)
   const breakpoints = useBreakpoint()
   const getEventsPath = () => {
       return lang === 'ca'
       ? alias
       : `/${lang}${alias}`
   }

   return (
     <section className="row events-list m-b-80 background-beig p-b-t-80 p-l-r-168">
       <div className="container- events-content p-l-28">
         <div className="row m-b-73">
           <div className="col-md-12">
             <h2 className="h2-medium-c H2-Tablet-Medium-C--green-dark"> {t(title)} </h2>
           </div>
         </div>
         <div className="row m-b-64">
         {!hidemonth &&
           <div className={` ${ breakpoints.md ? 'col-md-12' : 'col-md-1' } calendar-month m-r-24`}>
             <div className="c-content"> { t(month)} </div>
           </div>
         }

           {data && data.length > 0 && data.map(({node}, index) => {
             return(
               <Event
                  key={index}
                  title={Utils.getShortTitle(node.title)}
                  description={Utils.getDescription(node)}
                  datehour={
                    `${Utils.getDate(node, 'weekday', lang)},
                     ${Utils.getDate(node, 'day', lang)} ${Utils.startWithVowel(Utils.getDate(node, 'month', lang), lang) && lang ==='ca' ? "d'" : (!Utils.startWithVowel(Utils.getDate(node, 'month', lang), lang)  || lang == 'es')  ? 'de' : '' } ${Utils.getDate(node, 'month', lang)} ${t('a les')}  ${Utils.getDate(node, 'hour', lang)}h`}//"Diumenge, 17 d'abril a les 11:00h"
                  place= <span dangerouslySetInnerHTML={{ __html: Utils.getLocation(node) }} />
                  day={Utils.getDate(node, 'day', lang)}
                  month={Utils.getDate(node, 'shortMonth', lang)}
                  className={` ${ breakpoints.md ? 'col-md-6' : 'col-md-4' } calendar-event m-r-24 ${'item-'+index}`}
                  path={node?.path?.alias}
               />
             )
           })}
           {(!data || (data && data.length == 0)) &&
             <NoSearchResults classes="max-width-500" />
           }
         </div>
         <div className="row p-l-r-5 text-center">
           <div className="container">
             <button
              className="events-cta btn btn-outline btn-lg"
              onClick={() => navigate(Utils.getPath('/events', lang))}
              >{t("Veure esdeveniments")}
              </button>
           </div>
         </div>
       </div>
     </section>
   )


 }


 export default EventsList
